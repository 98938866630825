import React, { useState, useEffect, useCallback } from 'react';
import swal from 'sweetalert';
import Select from 'react-select';
import debounce from 'lodash/debounce';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './VisaForm.css';

const SERVICE_NAME_TO_ID = {
  'VNM eVisa Urgent New 1M - Single Entry': 1200026,
  'VNM eVisa Urgent New 3M - Mutiple Entries': 1200027,
  'VNM eVisa Urgent Fix': 1200029,
  'VNM eVisa Urgent Expedite': 1200028
};

const SERVICE_NAME_TO_PRICE = {
  'VNM eVisa Urgent New 1M - Single Entry': 360,
  'VNM eVisa Urgent New 3M - Mutiple Entries': 385,
  'VNM eVisa Urgent Fix': 275,
  'VNM eVisa Urgent Expedite': 325
};

const SERVICE_NAME_TO_PROCESSING_TIME = {
  'VNM eVisa Urgent New 1M - Single Entry': 'minimum 30 minutes before your boarding',
  'VNM eVisa Urgent New 3M - Mutiple Entries': 'minimum 30 minutes before your boarding',
  'VNM eVisa Urgent Fix': 'minimum 30 minutes before your boarding',
  'VNM eVisa Urgent Expedite': 'minimum 30 minutes before your boarding'
};

const VisaForm = () => {
  const [step, setStep] = useState('initialQuestion');
  const [visaService, setVisaService] = useState('');
  const [numberOfPassengers, setNumberOfPassengers] = useState(1);
  const [departureDateTime, setDepartureDateTime] = useState('');
  const [arrivalDateTime, setArrivalDateTime] = useState('');
  const [arrivalAirport, setArrivalAirport] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [passportFields, setPassportFields] = useState([]);
  const [departureAirport, setDepartureAirport] = useState(null);
  const [departureAirportOptions, setDepartureAirportOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoadingAirports, setIsLoadingAirports] = useState(false);

  useEffect(() => {
    createPassportFields(numberOfPassengers);
  }, []);
  const handleEvisaResponse = (hasEvisa) => {
    if (hasEvisa) {
      selectVisaService('VNM eVisa Urgent Fix');
    } else {
      setStep('visaQuestions');
    }
  };

  const handleEvisaFormSubmission = (hasSubmittedForm) => {
    if (hasSubmittedForm) {
      selectVisaService('VNM eVisa Urgent Expedite');
    } else {
      setStep('visaTypeSelection');
    }
  };

  const fetchDepartureAirports = async (inputValue, skipEmpty = false) => {
    if (skipEmpty && !inputValue) {
      return;
    }
    setIsLoadingAirports(true);
    try {
      const response = await fetch('https://meilisearch.ariadirectcorp.com/indexes/airport/search', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          q: inputValue,
          attributesToHighlight: ["*"],
          limit: 20
        })
      });
      const data = await response.json();
      const options = data.hits.filter(v => v.name).map(airport => ({
        value: airport,
        label: `${airport.name} (${airport.country_name}) - ${airport.iata}`
      }));
      setDepartureAirportOptions(options);
    } catch (error) {
      console.error("Error fetching departure airports:", error);
    } finally {
      setIsLoadingAirports(false);
    }
  };

  const debouncedFetchAirports = useCallback(
    debounce((inputValue) => {
      fetchDepartureAirports(inputValue, true);
    }, 300),
    []
  );

  const handleDepartureAirportInputChange = (inputValue) => {
    debouncedFetchAirports(inputValue);
  };

  const handleDepartureAirportChange = (selectedOption) => {
    setDepartureAirport(selectedOption);
  };

  const selectVisaService = (service) => {
    if (service === "VNM eVisa Urgent New 1M - Single Entry" || service === "VNM eVisa Urgent New 3M - Mutiple Entries") {
      swal("Thank you!", "Please conntact support@ariadirect.com or call 650-889-9891", "success").then(() => {
        window.location.href = "https://ariadirect.com";
      });
      return;
    }
    setVisaService(service);
    setStep('passportForm');
    createPassportFields(numberOfPassengers);
  };

  const createPassportFields = (number) => {
    const fields = Array.from({ length: number }, (_, i) => ({
      passportImage: null,
      portraitImage: null,
      evisaFile: null,
      registrationCode: ''
    }));
    setPassportFields(fields);
  };


  const handlePassportFieldChange = (index, field, value) => {
    const updatedFields = [...passportFields];
    updatedFields[index][field] = value;
    setPassportFields(updatedFields);
  };

  const handleFileChange = async (event, index, fieldName) => {
    const file = event.target.files[0];
    if (file) {
      if (fieldName === 'evisaFile') {
        // For eVisa files, don't resize, just read and encode
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64 = e.target.result.split(',')[1];
          handlePassportFieldChange(index, fieldName, base64);
        };
        reader.readAsDataURL(file);
      } else {
        const resizedImage = await resizeAndEncodeImage(file, 1.5);
        handlePassportFieldChange(index, fieldName, resizedImage);
      }
    }
  };

  const resizeAndEncodeImage = (file, maxSizeMB) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          let width = img.width;
          let height = img.height;
          let quality = 0.7;
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          if (width > height) {
            if (width > 1920) {
              height *= 1920 / width;
              width = 1920;
            }
          } else {
            if (height > 1920) {
              width *= 1920 / height;
              height = 1920;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          const compressAndCheck = () => {
            const base64 = canvas.toDataURL('image/jpeg', quality);
            const sizeInMB = (base64.length * 3 / 4) / (1024 * 1024);

            if (sizeInMB > maxSizeMB && quality > 0.1) {
              quality -= 0.1;
              compressAndCheck();
            } else {
              resolve(base64.split(',')[1]);
            }
          };

          compressAndCheck();
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const orderResponse = await createOrder();
      const paymentResponse = await createPayment(orderResponse);
      handlePaymentResponse(paymentResponse);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const createOrder = async () => {
    const passengers = passportFields.map(field => ({
      passport_base64: field.passportImage,
      portrait_base64: field.portraitImage,
      evisa_base64: field.evisaFile,
      registration_code: field.registrationCode,
    }));

    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/pkg/ets/public/external/orders`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        service_id: SERVICE_NAME_TO_ID[visaService],
        passengers,
        boarding_time: formatBoardingTime(departureDateTime),
        arrival_time: formatBoardingTime(arrivalDateTime),
        arrival_airport: arrivalAirport,
        departure_airport: departureAirport?.value,
        contact_phone: phoneNumber,
        contact_email: email
      })
    });

    return response.json();
  };

  const createPayment = async (orderResponse) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/payment/payments/public/create-payment-urls`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        method: paymentMethod,
        total: Number(orderResponse.price?.price) * numberOfPassengers,
        currency: "USD",
        description: `ORDER ${orderResponse.order_id} PAYMENT`,
        order_id: orderResponse.order_id
      })
    });

    return response.json();
  };

  const handlePaymentResponse = (paymentResponse) => {
    if (paymentMethod === 'ZELLEPAY') {
      swal({
        title: "ZELLEPAY Payment Information",
        text: `Please send payment to:\nEmail: payment@ariadirect.com\nMemo: VN Urgent eVisa Order ${paymentResponse.data.order_id}`,
        icon: "info",
        button: "OK",
      }).then(() => {
        swal("Thank you!", "We'll process your order once payment is received.", "success").then(() => {
          window.location.href = "https://ariadirect.com";
        });
      });
    } else {
      redirectToPayment(paymentResponse);
    }
  };

  const redirectToPayment = (paymentResponse) => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = paymentResponse.data.url;
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'token';
    input.value = paymentResponse.data.signature;
    form.appendChild(input);
    document.body.appendChild(form);
    form.submit();
  };

  const handleError = (error) => {
    console.error("Error processing request:", error);
    swal("Error", "Unable to process your request. Please try again.", "error");
  };

  const formatBoardingTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const timezoneOffset = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() - timezoneOffset);
    return date.toISOString();
  };

  return (
    <div className="container">
      <div className="header">
        <img src="https://ariadirect.com/static/media/logo.123548a7.svg" alt="Ariadirect" className="logo" />
        <h1>AriaDirect - Urgent eVisa</h1>
      </div>

      {step === 'initialQuestion' && (
        <div>
          <h2>Do you need to fix the incorrect Vietnam eVisa?</h2>
          <button onClick={() => handleEvisaResponse(true)} className="primary">Yes</button>
          <button onClick={() => handleEvisaResponse(false)}>No</button>
        </div>
      )}

      {step === 'visaQuestions' && (
        <div>
          <h2>Have you submitted Vietnam eVisa?</h2>
          <button onClick={() => handleEvisaFormSubmission(true)} className="primary">Yes</button>
          <button onClick={() => handleEvisaFormSubmission(false)}>No</button>
        </div>
      )}

      {step === 'visaTypeSelection' && (
        <div>
          <h2>Select Visa Type:</h2>
          <button onClick={() => selectVisaService('VNM eVisa Urgent New 1M - Single Entry')} className="primary">1 Month (Single Entry)</button>
          <button onClick={() => selectVisaService('VNM eVisa Urgent New 3M - Mutiple Entries')} className="primary">3 Months (Multiple Entries)</button>
        </div>
      )}

      {step === 'passportForm' && (
        <form onSubmit={handleSubmit}>
          <div className="product-info">
            <h3>{visaService}</h3>
            <p>Processing Time: <b>{SERVICE_NAME_TO_PROCESSING_TIME[visaService]}</b></p>
            <p>Unit Price: <b>${SERVICE_NAME_TO_PRICE[visaService]}</b></p>
            <p>Total Price:<b> ${SERVICE_NAME_TO_PRICE[visaService] * numberOfPassengers}</b></p>
          </div>

          <label>
            Number of Passengers
            <input
              type="number"
              value={numberOfPassengers}
              onChange={(e) => {
                setNumberOfPassengers(Number(e.target.value));
                createPassportFields(Number(e.target.value));
              }}
              min="1"
              required
            />
          </label>

          {passportFields.map((field, index) => (
            <div key={index} className="passportField">
              <h3>Passenger {index + 1}</h3>
              <label>
                Passport Image
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, index, 'passportImage')}
                  accept="image/*"
                  required
                />
              </label>
              {['VNM eVisa Urgent New 1M - Single Entry', 'VNM eVisa Urgent New 3M - Mutiple Entries', 'VNM eVisa Urgent Expedite'].includes(visaService) && (
                <label>
                  Portrait Photo
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(e, index, 'portraitImage')}
                    accept="image/*"
                    required
                  />
                </label>
              )}
              {visaService === 'VNM eVisa Urgent Fix' && (
                <label>
                  eVisa File
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(e, index, 'evisaFile')}
                    accept="image/*,.pdf"
                    required
                  />
                </label>
              )}
              {visaService === 'VNM eVisa Urgent Expedite' && (
                <label>
                  Registration Code
                  <input
                    type="text"
                    value={field.registrationCode}
                    onChange={(e) => handlePassportFieldChange(index, 'registrationCode', e.target.value)}
                    required
                  />
                </label>
              )}
            </div>
          ))}
          <label>
            Departure Airport
            <Select
              value={departureAirport}
              onChange={handleDepartureAirportChange}
              options={departureAirportOptions}
              onInputChange={handleDepartureAirportInputChange}
              placeholder="Search and select departure airport"
              isLoading={isLoadingAirports}
            />
          </label>
          <label>
            Departure Date and Time (Boarding Time on Flight Ticket)
            <input
              type="datetime-local"
              value={departureDateTime}
              onChange={(e) => setDepartureDateTime(e.target.value)}
              required
            />
          </label>

          <label>
            Arrival Date and Time (Estimated Arrival Time)
            <input
              type="datetime-local"
              value={arrivalDateTime}
              onChange={(e) => setArrivalDateTime(e.target.value)}
              required
            />
          </label>

          <label>
            Select Arrival Airport
            <select value={arrivalAirport} onChange={(e) => setArrivalAirport(e.target.value)} required>
              <option value="">Choose arrival airport</option>
              <option value="Noi Bai International Airport">Noi Bai International Airport (HAN)</option>
              <option value="Tan Son Nhat International Airport">Tan Son Nhat International Airport (SGN)</option>
              <option value="Da Nang International Airport">Da Nang International Airport (DAD)</option>
            </select>
          </label>

          <label>
            Phone Number
            <PhoneInput
              country={'us'}
              value={phoneNumber}
              onChange={(value, country) => setPhoneNumber(value)}
              inputProps={{
                required: true,
              }}
            />
          </label>

          <label>
            Email
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </label>

          <div className="payment-methods">
            <h3>Payment Method</h3>
            <div className="payment-option">
              <input
                type="radio"
                id="authorizenet"
                name="paymentMethod"
                value="AUTHORIZENET"
                checked={paymentMethod === 'AUTHORIZENET'}
                onChange={(e) => setPaymentMethod(e.target.value)}
                required
              />
              <label htmlFor="authorizenet" className="payment-label">
                <img src="https://www.authorize.net/conf/anet-2021/settings/wcm/templates/en-us-content-page-template/structure/_jcr_content/root/header/logo.img.jpg/1623941357644.jpg" alt="Authorize.net" className="payment-logo" />
                <span>Credit card</span>
              </label>
            </div>
            <div className="payment-option">
              <input
                type="radio"
                id="paypal"
                name="paymentMethod"
                value="PAYPAL"
                checked={paymentMethod === 'PAYPAL'}
                onChange={(e) => setPaymentMethod(e.target.value)}
                required
              />
              <label htmlFor="paypal" className="payment-label">
                <img src="https://www.paypalobjects.com/digitalassets/c/website/logo/full-text/pp_fc_hl.svg" alt="PayPal" className="payment-logo" />
                <span>PayPal</span>
              </label>
            </div>
            <div className="payment-option">
              <input
                type="radio"
                id="zellepay"
                name="paymentMethod"
                value="ZELLEPAY"
                checked={paymentMethod === 'ZELLEPAY'}
                onChange={(e) => setPaymentMethod(e.target.value)}
                required
              />
              <label htmlFor="zellepay" className="payment-label">
                <img src="https://ariadirect-jp-files.s3.amazonaws.com/2bdb4dfeb5dcd23993a4ebc1d891b8ef/image.png" alt="ZELLEPAY" className="payment-logo" />
                <span>ZELLEPAY</span>
              </label>
            </div>
          </div>

          <button type="submit" className="primary" disabled={loading}>
            {loading ? 'Processing...' : 'Proceed to Payment'}
          </button>
        </form>
      )}

      {loading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
};

export default VisaForm;